<template>
    <div class="card mb-3">
        <div class="card-header">
            <div class="row align-items-center justify-content-between">
                <div class="col-4 col-sm-auto">
                    <h5 class="fs-0 mb-0 text-nowrap">Products</h5>
                </div>
                <div class="col-6 col-sm-5 col-xxl-4">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="Search..."
                           aria-label="Search"
                           v-model="search">
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <LoadingSpinner v-if="loading" class="my-5" />
            <LoadingError v-else-if="error" class="my-6" />
            <VTable v-else-if="products.length"
                    :data="products"
                    :filter="search"
                    :filter-fields="['product_name', 'name', 'gtin']"
                    :page-size="15">
                <template #header="{ sort, sortBy }">
                    <tr class="bg-200 text-900 white-space-nowrap user-select-none">
                        <VTableHeader field="product_name" v-bind="{ sort, sortBy }">
                            Product Name
                        </VTableHeader>
                        <VTableHeader field="name" v-bind="{ sort, sortBy }">
                            APYX
                        </VTableHeader>
                        <VTableHeader field="gtin" v-bind="{ sort, sortBy }">
                            GTIN
                        </VTableHeader>
                        <th class="pr-3">
                            In Stock
                        </th>
                    </tr>
                </template>
                <template #row="{ item: product }">
                    <tr :id="product.id">
                        <td class="py-2 align-middle">{{ product.product_name }}</td>
                        <td class="py-2 align-middle text-truncate">{{ product.name }}</td>
                        <td class="py-2 align-middle">{{ product.gtin }}</td>
                        <td class="py-2 align-middle text-center white-space-nowrap">
                            <div class="custom-control custom-switch">
                                <input type="checkbox"
                                       v-model="product.is_in_stock"
                                       @change="toggleInStock(product)"
                                       :disabled="product.loading || !$auth.hasPermissions('update:products')"
                                       :id="`stock_${product.id}`"
                                       class="custom-control-input"
                                       aria-label="In Stock">
                                <label class="custom-control-label" :for="`stock_${product.id}`" />
                            </div>
                        </td>
                    </tr>
                </template>
            </VTable>
            <div v-else class="d-flex align-items-center justify-content-center flex-column p-3" style="height: 300px;">
                <i class="fas fa-store-slash" style="font-size: 2rem;" />
                <span class="mt-3">No products available yet.</span>
                <small class="mt-2 w-50 text-center">
                    Once a product is added to the storefront, it will appear in this section
                </small>
            </div>
        </div>
    </div>
</template>

<script>
    import { getProductList, updateProductVariant } from '../services/ProductService';
    import LoadingError from '../components/LoadingError';
    import LoadingSpinner from '../components/LoadingSpinner';
    import VTable from '../components/VTable.vue';
    import VTableHeader from '../components/VTableHeader.vue';

    export default {
        name: 'Products',
        components: {
            LoadingError,
            LoadingSpinner,
            VTable,
            VTableHeader,
        },
        data() {
            return {
                loading: true,
                error: null,
                products: [],
                search: '',
            };
        },
        async mounted() {
            try {
                const { data } = await getProductList();

                // This view actually lists ProductVariants, not Products
                const variants = [];
                data.forEach(product => {
                    product.variants.forEach(variant => {
                        variant.product_name = product.name;
                    });
                    variants.push(...product.variants);
                });

                this.products = variants;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        methods: {
            async toggleInStock(productVariant) {
                this.$set(productVariant, 'loading', true); // To enable reactivity
                try {
                    await updateProductVariant(productVariant.id, { is_in_stock: productVariant.is_in_stock });
                } catch {
                    productVariant.is_in_stock = !productVariant.is_in_stock; // Revert the value

                    this.$alerts.danger('Unable to Update Product',
                                        'Please contact support if this problem persists.');
                } finally {
                    productVariant.loading = false;
                }
            },
        },
    };
</script>
